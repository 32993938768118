<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="550px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <el-form
      :model="form"
      ref="ruleForm"
      status-icon
      :rules="rules"
      label-width="140px"
    >
      <el-form-item label="名称：" prop="name"
        ><el-input
          v-model.trim="form.name"
          placeholder="请输入名称"
          clearable
          maxlength="64"
          show-word-limit
          class="inputWidth"
      /></el-form-item>
      <el-form-item label="(菜单/页面)路径："
        ><el-input
          v-model.trim="form.entry"
          placeholder="请输入入口"
          clearable
          maxlength="64"
          show-word-limit
          class="inputWidth"
      /></el-form-item>
      <!-- <el-form-item label="icon图标：">
        <el-upload class="upload-box" action="/">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>  --> </el-form
    ><span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span></el-dialog
  >
</template>

<script>
export default {
  name: 'add_menu',
  props: {
    showDialog: Boolean,
    title: '',
    ifEdit: Boolean, //是否是编辑
    nodeData: {
      type: Object,
      default: () => {
        {
        }
      }
    }
  },
  data() {
    return {
      form: { name: '', entry: '', icon: '', disabled: 0, spaceAppId: 0 },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
        // entry: [{ required: true, message: '请输入入口', trigger: 'blur' }],
      }
    };
  },
  mounted() {},
  methods: {
    initData() {
      if (this.ifEdit) {
        this.form.name = this.nodeData.data.name;
        this.form.entry = this.nodeData.data.entry;
      } else {
        this.form.name = '';
        this.form.entry = '';
      }
    },
    closeFn() {
      this.$emit('closeDialog');
    },
    sureFn() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('sureDialog', this.form);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.inputWidth {
  width: 350px;
  margin-right: 15px;
}
// .upload-box {
//   ::v-deep .el-upload-dragger {
//     width: 50%;
//     height: 50%;
//   }
// }
</style>
